<template>
  <div class="content-container">
    <span class="title-login">Resetare parolă</span>
    <form @submit.prevent="requestPassReset" autocomplete="new-password" class="forgot_password">
    <div class="form-group has-feedback has-preloader">
      <label for="email" class="custom-label">Utilizator</label>
      <input v-model="email" id="email" type="text" class="form-control custom-input" name="email" value="" autofocus="" autocapitalize="false" autocorrect="false" autocomplete="new-password">
      <div class="spinner">
        <i class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i>
      </div>
    </div>
    <div class="form-group">
      <div class="text-center">
       <input type="submit" id="send_but_forgot" class="submit-btn white-font" value="Resetează parola" autocomplete="new-password" autocapitalize="false" autocorrect="false">
      </div>
    </div>
    </form>
  </div>
</template>

<script>
  import { REQUEST_PASSWORD_RESET } from '@/api.js'
  const getSessPass = () => {
    let x = sessionStorage.getItem('passwordReset')
    return x ? JSON.parse(x) : []
  }

  export default {
    data() {
      return {
        email: '',
        loading: false
      }
    },
    computed: {
      val() {
        return this.email.trim()
      }
    },
    methods: {
      saveSession(email) {
        const x = getSessPass()
        if(!x.find(e => e === email)) {
          x.push(email)
        }
        sessionStorage.setItem('passwordReset', JSON.stringify(x))
      },
      checkSession(email) {
        const x = getSessPass()
        return !!x.find(e => e === email)
      },
      requestPassReset() {
        if(this.checkSession(this.val)) {
          this.$toastr.w('Deja ați trimis o cerere către e-mail-ul dat. Încercați mai târziu.')
          return
        }
        if(this.loading) {
          return
        }
        this.loading = true

        if(!this.val) {
          this.$toastr.w('Username-ul nu este introdus corect.')
          return
        }
        (x => {
          REQUEST_PASSWORD_RESET(x).then(res => {
            if(res?.meta?.HttpStatusCode === 200) {
              this.$toastr.s('Cererea de resetare parolă a fost trimisă pe e-mail.')
              this.saveSession(x)
              this.goToHomePage()
            } else if(res?.statusCode === 401) {
              this.$toastr.e('Utilizatorul nu a fost găsit.')
            } else {
              this.$toastr.e('Încercați mai târziu')
            }
            this.loading = false
            this.email = x === this.val ? '' : this.email
          }).catch(err => {
            this.$toastr.e(err)
            this.laoding = false
            this.logout()
          })
        })(this.val)
      }
    }
  }
</script>